import { useCallback } from "react";
import { Cart, LeadAnswers } from "../contexts/heyFlowLead";
import { Button } from "./Button";
import { usePopupsContext } from "../contexts/popups";

export interface SubmitProps {
  heyflowId: string;
  cremationType: LeadAnswers["cremation"]["type"];
  cart: Cart;
  className: string;
}

export function Submit(props: SubmitProps) {
  const { setPopupType } = usePopupsContext();

  const setShowPopup = useCallback(() => {
    setPopupType({ type: "leaveEarly", heyflowId: props.heyflowId });
  }, [setPopupType, props.heyflowId]);

  const needsUrn = !props.cart.find((cart) => cart.type === "urn") && props.cremationType === "Einzelkremierung";

  return (
    <>
      {needsUrn ? (
        <div className={props.className}>
          <Button
            caption="Auftrag jetzt abschließen"
            fullWidth={true}
            primary={true}
            large={true}
            onClick={() => {
              setShowPopup();
            }}
          ></Button>
        </div>
      ) : (
        <a href={`https://animaltree.de/shopweiterleitung/#${props.heyflowId}`} className={props.className}>
          <Button
            className={props.className}
            caption="Auftrag jetzt abschließen"
            fullWidth={true}
            primary={true}
            large={true}
          ></Button>
        </a>
      )}
    </>
  );
}
