import { Cart, CartItem, Lead, StandardProducts } from "../contexts/heyFlowLead";
import { Product, ProductAndVariation, ProductVariation } from "../contexts/products";
import { jsonBodyFetch, queryFetch } from "./fetch";

export async function fetchLead(heyflowId: string) {
  const result = await queryFetch<undefined, { lead: Lead; cart: Cart }>(`/lead/${heyflowId}`, "get", undefined);
  result.cart = result.cart.filter((cartItem) => cartItem.dontShowInFrontEnd !== true);
  return result;
}

export async function fetchProduct(heyflowId: string, productId: string) {
  return queryFetch<undefined, Product>(`/product/${heyflowId}/${productId}`, "get", undefined);
}

export async function fetchStandardProducts(heyflowId: string) {
  return queryFetch<undefined, StandardProducts>(`/standardproducts/${heyflowId}`, "get", undefined);
}

export type ProductType = "urnen" | "beschriftung" | "schmuck";

export async function fetchProductList(heyflowId: string, productType: ProductType) {
  return queryFetch<undefined, Product[]>(`/${productType}/${heyflowId}`, "get", undefined);
}

export async function fetchProductVariations(heyflowId: string, productId: string) {
  return queryFetch<undefined, ProductVariation[]>(`/variations/${heyflowId}/${productId}`, "get", undefined);
}

export async function fetchProductAndVariations(heyflowId: string, productId: string) {
  return queryFetch<undefined, ProductAndVariation>(`/product_variations/${heyflowId}/${productId}`, "get", undefined);
}

export async function storeContacts(heyflowId: string, name: string, email: string, location: string) {
  return jsonBodyFetch<{ name: string; email: string; location: string }, Lead>(`/contact/${heyflowId}`, "put", {
    name,
    email,
    location,
  });
}

export async function storeCart(heyflowId: string, cart: CartItem[]) {
  return jsonBodyFetch<{ cart: CartItem[] }, void>(`/cart/${heyflowId}`, "put", {
    cart,
  });
}
