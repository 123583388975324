export interface ButtonProps {
  caption: string;
  fullWidth: boolean;
  primary: boolean;
  large?: boolean;
  icon?: string;
  className?: string;
  inactive?: boolean;
  onClick?: () => void;
}

export function Button(props: ButtonProps) {
  return (
    <button
      type="button"
      className={`items-center justify-center text-center px-4 lg:px-8 rounded-xl leading-tight font-normal transition-colors duration-300 ${
        props.primary ? "bg-green text-white hover:bg-greenhover" : "bg-neutral-200 hover:bg-neutral-300"
      } ${props.className ? props.className : " "} ${props.fullWidth ? "flex w-full" : "inline-flex"} 
      ${props.large ? " text-base md:text-lg h-12 lg:h-14" : "text-base py-3 lg:py-3"} ${
        props.inactive ? "opacity-40" : "cursor-pointer"
      }`}
      onClick={props.onClick}
    >
      {props.icon && <img src={props.icon} alt="icon" width="29" className="mr-3" />}
      {props.caption}
    </button>
  );
}
