import { useCallback, useState } from "react";
import { createContextAndProvider } from ".";
import { useAsyncValues } from "../hooks/useAsyncValue";
import { ProductType, fetchProductAndVariations, fetchProductList } from "../utils/api";

export interface ProductImage {
  id: number;
  src: string;
  name: string;
  alt: string;
}

export interface ProductAttribute {
  id: number;
  name: string;
  position: number;
  visible: boolean;
  variation: boolean;
  options: string[];
}

export interface ProductVariationAttribute {
  id: number;
  name: string;
  option: string;
}

export interface ProductDimension {
  length: string;
  width: string;
  height: string;
}

export interface ProductCategory {
  id: number;
  name: string;
  slug: string;
}

export interface Product {
  id: number;
  name: string;
  price: string;
  price_html: string;
  categories: ProductCategory[];
  weight: string;
  dimensions: ProductDimension;
  variations: number[];
  images: ProductImage[];
  attributes: ProductAttribute[];
  short_description: string;
  description: string;
  stock_status: "instock" | "outofstock";
}

export interface ProductVariation {
  id: number;
  price: string;
  attributes: ProductVariationAttribute[];
  weight: string;
  dimensions: ProductDimension;
}

export interface ProductAndVariation {
  product: Product;
  variations?: ProductVariation[];
}

const useProducts = () => {
  const [products, setProducts] = useState<Record<string, Product>>({});
  const [productLists, setProductLists] = useState<Record<string, number[]>>({});
  const [productVariations, setProductVariations] = useState<Record<string, ProductVariation[]>>({});

  const productAndVariantFetcher = useCallback(
    async (heyflowId: string, productId: string) => {
      let product = products[productId];
      let variation = productVariations[productId];

      if (product === undefined || variation === undefined) {
        if (product !== undefined && product.variations.length === 0) {
          variation = [];
        } else {
          const result = await fetchProductAndVariations(heyflowId, productId);
          product = result.product;
          variation = result.variations ?? [];
        }

        setProducts((products) => ({ ...products, [productId]: product }));
        setProductVariations((variations) => ({ ...variations, [productId]: variation }));
      }

      return { product, variation };
    },
    [products, productVariations]
  );

  const productListFetcher = useCallback(async (heyflowId: string, productType: ProductType) => {
    const result = await fetchProductList(heyflowId, productType);

    setProductLists((productLists) => ({ ...productLists, [productType]: result.map((product) => product.id) }));
    setProducts((products) => {
      result.forEach((product) => (products[product.id] = product));
      return products;
    });
    return result;
  }, []);

  const asyncProduct = useAsyncValues(productAndVariantFetcher);
  const asyncProductList = useAsyncValues(productListFetcher);

  return {
    asyncProduct,
    asyncProductList,
    productLists,
  };
};

const { useContext, Provider } = createContextAndProvider(useProducts);
export const useProductsContext = useContext;
export const ProductsProvider = Provider;
