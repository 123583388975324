import { useState } from "react";
import { createContextAndProvider } from ".";
import { ProductImage } from "./products";

export interface PopupTypePayment {
  type: "payment";
}

export interface PopupTypeImageDetails {
  type: "imageDetails";
  image: ProductImage;
}

export interface PopupTypeMessage {
  type: "message";
  message: string;
}

export interface PopupTypeLeaveEarly {
  type: "leaveEarly";
  heyflowId: string;
}

export interface PopupNegativeLocation {
  type: "negativeLocation";
}

export type PopupTypes =
  | PopupTypePayment
  | PopupTypeImageDetails
  | PopupTypeMessage
  | PopupTypeLeaveEarly
  | PopupNegativeLocation;

const usePopups = () => {
  const [popupType, setPopupType] = useState<PopupTypes | undefined>(undefined);

  return {
    popupType,
    setPopupType,
  };
};

const { useContext, Provider } = createContextAndProvider(usePopups);
export const usePopupsContext = useContext;
export const PopupsProvider = Provider;
