export interface SpinnerProps {
  size: "small" | "medium" | "large";
}

export function Spinner(props: SpinnerProps) {
  let sizeClasses: string;

  switch (props.size) {
    case "small":
      sizeClasses = "border-4 w-8 h-8";
      break;
    case "medium":
      sizeClasses = "border-6 w-20 h-20";
      break;
    case "large":
      sizeClasses = "border-8 w-32 h-32";
      break;
  }

  return (
    <div className={` border-gray-400 w-32 h-32 border-t-transparent rounded-full animate-spin ${sizeClasses}`}></div>
  );
}
