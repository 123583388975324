import { useCallback, useEffect, useState } from "react";
import { createContextAndProvider } from ".";
import { useAsyncValue } from "../hooks/useAsyncValue";
import { fetchLead, fetchStandardProducts, storeCart, storeContacts } from "../utils/api";
import { Product, ProductVariation, ProductVariationAttribute } from "./products";
import { usePopupsContext } from "./popups";

type Weight =
  | "bis 1 kg"
  | "1,1 - 5 kg"
  | "5,1 - 10 kg"
  | "10,1 - 15 kg"
  | "15,1 - 25 kg"
  | "25,1 - 45 kg"
  | "über 45 kg";

export interface LeadAnswers {
  animalType: "dein Hund" | "deine Katze" | "dein Tier";
  concern: "verstorben" | "anstehend" | "vorsorglich";
  cremation:
    | { type: "Einzelkremierung"; urn: "keramik Urne" | "bio. abbaubarer Urne" | "no urn" }
    | { type: "Sammelkremierung" };
  weight: Weight;
  pawPrint: "Ja" | "Nein";
  pickup: "Abholung" | "Abgabe";
}

export interface Lead {
  lead: LeadAnswers;
  name: string;
  email: string;
  location: string;
  region: string;
  offerId: string;
}

export type Cart = CartItem[];

export type CartItemType =
  | "cremation"
  | "energy"
  | "pawPrint"
  | "transport"
  | "urn"
  | "inscription"
  | "jewelry"
  | "certificate"
  | "diamondConsultation";

export interface CartItem {
  name: string;
  price: number;
  productId: number;
  variationId?: number;
  variationAttributes?: ProductVariationAttribute[];
  description: string;
  image: string;
  type: CartItemType;
  forwardedToWooCommerce?: boolean;
  dontShowInFrontEnd?: boolean;
}

export interface StandardProduct {
  product: Product;
  variations?: ProductVariation[];
}

export type StandardProducts = Record<string, StandardProduct>;

export function transformProductToCartItem(product: Product, type: CartItemType): CartItem {
  return {
    name: product.name,
    price: Math.round(Number(product.price) * 100) / 100,
    productId: product.id,
    description: product.short_description || product.description,
    image:
      product.images?.[0]?.src ??
      "https://animaltree.de/wp-content/uploads/2022/01/Blaetter-Logo_high_res-1024x1024.png",
    type,
  };
}

const useHeyFlowLead = () => {
  const [heyflowId, setHeyflowId] = useState<string | undefined>();
  const { setPopupType } = usePopupsContext();

  const heyFlowLeadsFetcher = useAsyncValue(fetchLead, 5);
  const { error, isLoading, value, fetch, setValue } = heyFlowLeadsFetcher;

  const standardProductsFetcher = useAsyncValue(fetchStandardProducts, 5);
  const { value: standardProducts, fetch: doFetchStandardProducts } = standardProductsFetcher;

  useEffect(() => {
    if (heyflowId !== undefined) {
      fetch(heyflowId);
      doFetchStandardProducts(heyflowId);
    } else {
      setValue(undefined);
    }
  }, [heyflowId, fetch, doFetchStandardProducts, setValue]);

  useEffect(() => {
    if (value?.lead.region === "") {
      setPopupType({ type: "negativeLocation" });
    }
  }, [value?.lead.region, setPopupType]);

  const storeLead = useCallback(
    (lead: Lead) => {
      (async () => {
        setValue((value) => (value ? { ...value, lead } : undefined));

        if (heyflowId !== undefined && lead !== undefined) {
          const newLead = await storeContacts(heyflowId, lead.name, lead.email, lead.location);
          setValue((value) => (value ? { ...value, lead: newLead } : undefined));
        }
      })();
    },
    [heyflowId, setValue]
  );

  const addCartItem = useCallback(
    (cartItem: CartItem) => {
      if (value !== undefined) {
        let filteredCartItems = cartItem.type === "urn" ? value.cart.filter((c) => c.type !== "urn") : value.cart;
        if (cartItem.productId === 41033 /* Leinensäckchen */) {
          filteredCartItems = filteredCartItems.filter((c) => c.type !== "inscription");
        }
        const newCart = [...filteredCartItems, cartItem];
        setValue({ cart: newCart, lead: value.lead });
        if (heyflowId) {
          storeCart(heyflowId, newCart);
        }
      }
    },
    [value, setValue, heyflowId]
  );

  const changeCartItem = useCallback(
    (cartItem: CartItem, index: number) => {
      if (value !== undefined) {
        const newCart = [...value.cart];
        newCart[index] = cartItem;
        setValue({ cart: newCart, lead: value.lead });
        if (heyflowId) {
          storeCart(heyflowId, newCart);
        }
      }
    },
    [value, setValue, heyflowId]
  );

  const removeCartItem = useCallback(
    (index: number) => {
      if (value !== undefined) {
        const newCart = [...value.cart.slice(0, index), ...value.cart.slice(index + 1)];
        setValue({ cart: newCart, lead: value.lead });
        if (heyflowId) {
          storeCart(heyflowId, newCart);
        }
      }
    },
    [value, setValue, heyflowId]
  );

  return {
    error,
    isLoading,
    value,
    heyflowId,
    standardProducts,
    addCartItem,
    changeCartItem,
    removeCartItem,
    setValue,
    storeLead,
    setHeyflowId,
  };
};

const { useContext, Provider } = createContextAndProvider(useHeyFlowLead);
export const useHeyFlowLeadContext = useContext;
export const HeyFlowLeadProvider = Provider;
