import { Link } from "react-router-dom";
import { CartItemType, useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { anotherProduct, createStandardProductDefinitions, productShops, simpleProducts } from "../utils/productTypes";
import { ProductListing } from "./ProductListing";
import { Fragment } from "react";

export interface OfferDetailsProps {
  heyflowId: string;
}

export function OfferDetails(props: OfferDetailsProps) {
  const { error, value, standardProducts, addCartItem } = useHeyFlowLeadContext();

  if (error) {
    return null;
  }

  if (!value || !standardProducts) {
    return null;
  }

  const { placeholderProducts } = createStandardProductDefinitions(standardProducts);

  const isSingleCremation = value.lead.lead.cremation.type === "Einzelkremierung";

  const standardCategories: CartItemType[] = isSingleCremation
    ? ["cremation", "urn", "inscription", "transport", "pawPrint", "jewelry"]
    : ["cremation", "transport", "pawPrint"];

  const extraCategories: CartItemType[] = isSingleCremation ? ["certificate", "diamondConsultation"] : ["certificate"];

  const superCategories: [string, CartItemType[]][] = [
    [`Details zum Angebot #${value.lead.offerId}`, standardCategories],
    ["Weitere Optionen für die Bestattung", extraCategories],
  ];

  const hasProperUrn = value.cart.filter((cart) => cart.type === "urn" && cart.productId !== 41033).length > 0;

  return (
    <div>
      {superCategories.map(([title, categories], superCategoryIndex) => (
        <Fragment key={superCategoryIndex}>
          <div className="font-normal font-shadows text-green text-2xl md:text-4xl leading-snug mb-5 mt-5">{title}</div>
          <div className="mb-10">
            {categories
              .filter((category) => category !== "inscription" || hasProperUrn)
              .map((category) => (
                <Fragment key={category}>
                  {value.cart
                    .map((cartItem, index) => ({ cartItem, cartItemIndex: index }))
                    .filter(({ cartItem }) => cartItem.type === category)
                    .map(({ cartItem, cartItemIndex }) => (
                      <ProductListing
                        heyflowId={props.heyflowId}
                        key={cartItemIndex}
                        title={cartItem.name}
                        price={cartItem.price}
                        variationAttributes={cartItem.variationAttributes}
                        shortDescription={cartItem.description}
                        image={cartItem.image}
                        type={{
                          type: "cart",
                          cartItemIndex,
                          cartItemType: category,
                          productId: cartItem.productId,
                          variationId: cartItem.variationId,
                        }}
                        onClick={() => {}}
                      ></ProductListing>
                    ))}
                  {value.cart.filter((cart) => cart.type === category).length === 0 ? (
                    <ProductListing
                      heyflowId={props.heyflowId}
                      title={placeholderProducts[category].name}
                      price={placeholderProducts[category].price >= 0 ? placeholderProducts[category].price : undefined}
                      shortDescription={placeholderProducts[category].description}
                      image={placeholderProducts[category].image}
                      type={{
                        type: "placeholder",
                        cartItemType: category,
                      }}
                      onClick={() => {}}
                    ></ProductListing>
                  ) : (
                    anotherProduct[category] &&
                    (simpleProducts.indexOf(category) !== -1 ? (
                      <button
                        type="button"
                        className="text-sm mt-2 lg:mt-3 block underline"
                        onClick={() => {
                          addCartItem(placeholderProducts[category]);
                        }}
                      >
                        {anotherProduct[category]}
                      </button>
                    ) : (
                      <Link
                        className="text-sm mt-2 lg:mt-3 block underline"
                        to={productShops[category]?.(props.heyflowId) ?? ""}
                      >
                        {anotherProduct[category]}
                      </Link>
                    ))
                  )}
                </Fragment>
              ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
}
