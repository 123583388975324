import { useCallback } from "react";
import { useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { Spinner } from "./Spinner";
import { Link } from "react-router-dom";
import { usePopupsContext } from "../contexts/popups";

interface PriceTableItemProps {
  name: string;
  price: number;
  bold?: boolean;
}

function PriceTableItem(props: PriceTableItemProps) {
  return (
    <div
      className={`flex my-2 justify-between text-base md:text-lg font-normal ${
        props.bold === true ? "font-semibold" : ""
      }`}
    >
      <span>{props.name}</span>
      <span className="shrink-0 ml-8">{props.price} €</span>
    </div>
  );
}

export interface PriceTableProps {
  heyflowId: string | undefined;
}

export function PriceTable(props: PriceTableProps) {
  const { setPopupType } = usePopupsContext();
  const { value, isLoading } = useHeyFlowLeadContext();

  const showPaymentPopup = useCallback(() => {
    setPopupType({ type: "payment" });
  }, [setPopupType]);

  if (isLoading || value === undefined) {
    return (
      <div className="flex justify-center my-10">
        <Spinner size="large"></Spinner>
      </div>
    );
  }

  const sum = value.cart.reduce((a, b) => a + b.price, 0);

  return (
    <>
      <div className="border-t border-black mt-4 lg:mt-8 pt-2"></div>
      {value.cart.map((entry, index) => (
        <PriceTableItem key={index} {...entry}></PriceTableItem>
      ))}
      {!value.cart.find((cart) => cart.type === "urn") && value.lead.lead.cremation.type === "Einzelkremierung" && (
        <div className="flex my-2 justify-between text-base md:text-lg font-normal">
          <span>Urne</span>
          <Link className="text-green" to={`/${props.heyflowId}/products/urnen`}>
            Auswählen
          </Link>
        </div>
      )}
      <div className="border-t border-black mt-4"></div>
      <div className="lg:sticky lg:top-0 bg-white pt-2 pb-0 z-10">
        <PriceTableItem name="Gesamtsumme" price={sum} bold={true}></PriceTableItem>
        <div className="text-sm pb-4 font-normal">
          <span className="pr-5 max-md:text-xs">
            Inkl. MwSt und Zusatzkosten{" "}
            <span className="underline font-light cursor-pointer" onClick={showPaymentPopup}>
              Ratenzahlung möglich
            </span>
          </span>
        </div>
        <div className="border-b border-black mb-8 pb-2"></div>
      </div>

      {value?.lead.region && (
        <div className="md:text-xl font-normal">
          Betreuender Standort: <span className="font-semibold">{value?.lead.region}</span>
        </div>
      )}
    </>
  );
}
