import { useEffect } from "react";
import { useProductsContext } from "../contexts/products";

import placeholder from "./assets/placeholder.png";
import { Spinner } from "./Spinner";
import { Link, useNavigate } from "react-router-dom";
import { useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { ProductType } from "../utils/api";
import { WordPressImage } from "./WordPressImage";
import { Button } from "./Button";

const PRODUCTS_PER_PAGE = 20;

export interface ProductShopProps {
  heyflowId: string;
  page: number;
  type: ProductType;
}

export function ProductShop(props: ProductShopProps) {
  const { page } = props;
  const { asyncProductList } = useProductsContext();
  const { heyflowId } = props;
  const asyncProductListFetch = asyncProductList.fetch;

  const navigate = useNavigate();

  const { value } = useHeyFlowLeadContext();

  const productList = asyncProductList.getValue(heyflowId, props.type);

  useEffect(() => {
    if (heyflowId !== undefined && productList === undefined) {
      asyncProductListFetch(heyflowId, props.type);
    }
  }, [heyflowId, asyncProductListFetch, productList, props.type]);

  const pages = [];
  if (productList !== undefined) {
    for (let i = 0; i * PRODUCTS_PER_PAGE < productList.length; i += 1) {
      pages.push(i);
    }
  }

  return (
    <div>
      <div className="flex justify-end mt-4">
        {productList !== undefined && <div>{productList.length} Artikel</div>}
      </div>

      {asyncProductList.getIsLoading(heyflowId, props.type) && (
        <>
          <div className="flex justify-center mt-20">
            <Spinner size="large"></Spinner>
          </div>
          <div className="text-center text-base md:text-lg mt-10">Produkte werden geladen...</div>
        </>
      )}

      {productList !== undefined && (
        <>
          <div className="flex flex-wrap">
            {productList.slice(0, (page + 1) * PRODUCTS_PER_PAGE).map((product, index) => (
              <div key={product.id} className="w-1/2 lg:w-1/4 p-3">
                <Link to={`/${heyflowId}/product/${product.id}`}>
                  <WordPressImage
                    alt={product.images?.[0]?.alt ?? "placeholder"}
                    src={product.images?.[0]?.src ?? placeholder}
                    showThumbNail={true}
                  />
                  <div className="text-sm pt-5">{product.name}</div>
                  <div className="flex justify-between items-center mt-3">
                    <div dangerouslySetInnerHTML={{ __html: product.price_html }}></div>
                    {value?.cart.some((cartItem) => cartItem.productId === product.id) && (
                      <div
                        className={`items-center justify-center text-center px-4 rounded-full text-sm transition-opacity
 bg-green text-white inline-flex py-1 hover:opacity-100 opacity-80 cursor-pointer ml-1`}
                      >
                        Ausgewählt
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>
          {(page + 1) * PRODUCTS_PER_PAGE < productList.length && (
            <div className="flex justify-center mt-10">
              <Button
                caption="Weitere Produkte anzeigen"
                onClick={() => {
                  navigate(`/${heyflowId}/products/${props.type}/${page + 2}`, {
                    replace: true,
                    preventScrollReset: true,
                  });
                }}
                fullWidth={false}
                primary={false}
                large={false}
              ></Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
