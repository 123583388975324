import configuration from "../configuration";
import { useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { Button } from "./Button";
import { PriceTable } from "./PriceTable";
import { Submit } from "./Submit";
import download from "./assets/download.png";
import lamp from "./assets/lamp.png";

export interface OfferProps {
  heyflowId: string | undefined;
  scrollToDetails: () => void;
}

export function Offer(props: OfferProps) {
  const { error, value } = useHeyFlowLeadContext();

  if (error) {
    return (
      <div className="text-base md:text-lg my-10 text-red-700">
        Daten konnten nicht geladen werden. Probieren Sie es später noch einmal.
      </div>
    );
  }

  return (
    <>
      {props.heyflowId && value && (
        <>
          <div className="font-normal font-shadows text-green text-3xl md:text-4xl leading-relaxed">
            Mein Angebot {value ? `#${value.lead.offerId} ` : ""}
            für eine individuelle Tierbestattung
          </div>

          <PriceTable heyflowId={props.heyflowId}></PriceTable>
          <div className="flex items-center justify-center mt-5">
            <img src={lamp} width="25" alt="lamp"></img>
            <span className="text-neutral-500 text-sm font-medium pl-2">
              Dein Angebot wurde gespeichert. Du kannst es{" "}
              <button type="button" className="underline" onClick={props.scrollToDetails}>
                hier
              </button>{" "}
              jederzeit verändern.
            </span>
          </div>
          <Submit
            heyflowId={props.heyflowId}
            cart={value.cart}
            cremationType={value.lead.lead.cremation.type}
            className="mt-7 mb-5 block"
          />
          <a
            href={`${configuration.netlify.url}/api/kva/${props.heyflowId}/AnimalTree_Angebot_${value.lead.offerId}.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              caption="Angebot als PDF herunterladen"
              onClick={() => {}}
              fullWidth={true}
              primary={false}
              icon={download}
              large={true}
            ></Button>
          </a>
        </>
      )}
      <div className="mb-12 lg:mb-20"></div>
    </>
  );
}
