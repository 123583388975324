import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { Header } from "../components/Header";
import { Offer } from "../components/Offer";
import { Contact } from "../components/Contact";
import { OfferDetails } from "../components/OfferDetails";
import { NextSteps } from "../components/NextSteps";
import { Container } from "../components/Container";
import { Spinner } from "../components/Spinner";
import { Footer } from "../components/Footer";
import { Popup } from "../components/Popup";

export function Main() {
  let { heyflowId } = useParams();
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const { setHeyflowId, isLoading, value } = useHeyFlowLeadContext();

  useEffect(() => {
    setHeyflowId(heyflowId);
  }, [setHeyflowId, heyflowId]);

  return (
    <div className="min-h-screen flex flex-col">
      <Header></Header>
      <div className="flex-1">
        <Container className="flex-1">
          {isLoading || value === undefined ? (
            <>
              <div className="flex justify-center my-10">
                <Spinner size="large"></Spinner>
              </div>
              <span className="text-center block text-base md:text-lg">Dein Angebot wird geladen</span>
            </>
          ) : (
            <>
              <div className="max-lg:hidden lg:block">
                <div className="flex flex-col lg:flex-row justify-between">
                  <div className="shrink-0 lg:w-7/12">
                    <Offer
                      heyflowId={heyflowId}
                      scrollToDetails={() => {
                        ref2.current?.scrollIntoView({ behavior: "smooth" });
                      }}
                    ></Offer>
                    <div ref={ref2}>{heyflowId && <OfferDetails heyflowId={heyflowId}></OfferDetails>}</div>
                  </div>
                  <div className="shrink-0 lg:w-3/12 lg:self-start top-0 sticky pt-5">
                    {heyflowId && <Contact heyflowId={heyflowId}></Contact>}
                  </div>
                </div>

                <NextSteps></NextSteps>
                <div className="font-normal font-shadows text-green text-3xl md:text-4xl mt-10 lg:mt-20 mb-8 leading-snug">
                  Das sagen unsere Kunden
                </div>
                <div className="elfsight-app-f790dca5-c572-45d2-9781-ad6d4f8e173d"></div>
              </div>

              <div className="max-lg:block lg:hidden">
                <Offer
                  heyflowId={heyflowId}
                  scrollToDetails={() => {
                    ref1.current?.scrollIntoView({ behavior: "smooth" });
                  }}
                ></Offer>
                {heyflowId && <Contact heyflowId={heyflowId}></Contact>}

                <div ref={ref1}>{heyflowId && <OfferDetails heyflowId={heyflowId}></OfferDetails>}</div>

                <NextSteps></NextSteps>
                <div className="font-normal font-shadows text-green text-2xl md:text-4xl mt-10 lg:mt-20 mb-8 leading-snug">
                  Das sagen unsere Kunden
                </div>
                <div className="elfsight-app-f790dca5-c572-45d2-9781-ad6d4f8e173d"></div>
              </div>
            </>
          )}
        </Container>
      </div>
      <Footer></Footer>
      <Popup />
    </div>
  );
}
