import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Header";

import previous from "../components/assets/back.png";
import { ProductShop } from "../components/ProductShop";
import { Container } from "../components/Container";
import { useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { useEffect } from "react";
import { Footer } from "../components/Footer";
import { Popup } from "../components/Popup";

export function Products() {
  let { heyflowId, page, type } = useParams();

  const { setHeyflowId } = useHeyFlowLeadContext();

  useEffect(() => {
    setHeyflowId(heyflowId);
  }, [setHeyflowId, heyflowId]);

  const navigate = useNavigate();

  let title = undefined;
  switch (type) {
    case "urnen":
      title = "Unsere Auswahl an Urnen";
      break;
    case "schmuck":
      title = "Unsere Auswahl an Schmuck und Andenken";
      break;
    case "beschriftung":
      title = "Unsere Auswahl an Beschriftungen";
      break;
    default:
      navigate(`/${heyflowId}`);
      return null;
  }

  const parsedPage = Number(page);

  return (
    <div className="min-h-screen flex flex-col">
      <Header></Header>
      <div className="flex-1">
        <Container className="flex-1">
          <Link to={`/${heyflowId}`} className="flex items-center">
            <img src={previous} alt="previous" className="w-7"></img>
            <span className="text-base pl-2">Zurück zum Angebot</span>
          </Link>
          <div className="font-normal font-shadows text-green text-3xl md:text-4xl leading-relaxed mt-5">{title}</div>

          {heyflowId !== undefined && (
            <ProductShop type={type} heyflowId={heyflowId} page={isNaN(parsedPage) ? 0 : parsedPage - 1}></ProductShop>
          )}
        </Container>
      </div>
      <Footer></Footer>
      <Popup />
    </div>
  );
}
