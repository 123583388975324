const configuration = {
  api: {
    url: "https://idif4mmw63.execute-api.eu-central-1.amazonaws.com",
  },
  netlify: {
    url: "https://angebot.animaltree.de",
  },
  shopweiterleitung: {
    url: "https://animaltree.de/shopweiterleitung/",
  },
  animaltree: {
    url: "https://animaltree.de",
    urnShop: "https://haustierurnen.de/",
  },
};

export default configuration;
