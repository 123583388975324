import { useEffect, useState } from "react";

export interface WordPressImageProps {
  src: string;
  alt: string;
  showThumbNail: boolean;
  className?: string;
  width?: string | number;
  height?: string | number;
}

export function WordPressImage(props: WordPressImageProps) {
  const { src, showThumbNail, alt, className, width, height } = props;
  const [largeImageLoaded, setLargeImageLoaded] = useState<boolean>(false);

  const extensionIndex = src.lastIndexOf(".");
  const thumbnailSrc = `${src.slice(0, extensionIndex)}-400x400${src.slice(extensionIndex)}`;

  const [actualSrc, setActualSrc] = useState<string>(thumbnailSrc);

  useEffect(() => {
    setLargeImageLoaded(false);
    setActualSrc(thumbnailSrc);
  }, [thumbnailSrc]);

  if (showThumbNail === false) {
    return (
      <div className={`${className ? className : ""} relative`}>
        <img
          src={actualSrc}
          alt={alt}
          onError={() => {
            if (actualSrc !== src) {
              setActualSrc(src);
            }
          }}
          className="w-screen top-0 left-0"
          width={width}
          height={height}
        />
        <img
          src={src}
          alt={alt}
          className={`absolute top-0 left-0 ${largeImageLoaded ? "" : "hidden"}`}
          width={width}
          height={height}
          onLoad={() => setLargeImageLoaded(true)}
        />
      </div>
    );
  }

  return (
    <img
      src={actualSrc}
      alt={alt}
      onError={() => {
        if (actualSrc !== src) {
          setActualSrc(src);
        }
      }}
      className={className}
      width={width}
      height={height}
    />
  );
}
