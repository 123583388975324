import logo from "./assets/AT-Logo_Web.png";
import pin from "./assets/pin.png";
import heart from "./assets/heart.png";
import auto from "./assets/auto.png";
import phone from "./assets/telephone-handle-silhouette.png";
import { Container } from "./Container";

export function Header() {
  return (
    <div className="font-normal text-slate-700 mb-7 lg:mb-14">
      <div className="hidden lg:block bg-neutral-100 py-4">
        <Container>
          <div className="flex justify-between text-sm">
            <div>
              <img src={pin} width="18" alt="pin" className="inline mr-4 align-middle"></img>
              <span className="align-middle">Deutschlandweit in deiner Nähe</span>
            </div>
            <div>
              <img src={auto} width="22" alt="auto" className="inline mr-4 align-middle"></img>
              <span className="align-middle">Schnelle Abholung oder Abgabe</span>
            </div>
            <div>
              <img src={heart} width="18" alt="heart" className="inline mr-4 align-middle"></img>
              <span className="align-middle">Über 12.300 Tierhalter begleitet</span>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="mt-4 lg:mt-10">
          <div className="flex justify-between items-center">
            <img src={logo} alt="logo" className="w-56 max-md:w-44"></img>
            <a
              href="tel:+4924037825575"
              className="text-sm font-normal max-md:w-10 max-md:h-10 max-md:bg-neutral-200 max-md:rounded-full max-md:flex max-md:justify-center max-md:items-center"
            >
              <img src={phone} alt="phone" className="inline align-middle w-6 max-md:w-5"></img>
              <span className="ml-4 hidden md:inline align-middle">+49 2403 7825575</span>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
