import configuration from "../configuration";

export async function queryFetch<T extends Record<string, string> | undefined, S>(
  path: string,
  method: string,
  args: T
): Promise<S> {
  const query = new URLSearchParams(args).toString();

  const apiPath = `${configuration.api.url}${path}`;
  const response = await fetch(query ? `${apiPath}?${query}` : apiPath, {
    method,
  });

  if (response.status === 401) {
    throw new Error(`Unauthorized`);
  }

  if (response.status >= 400) {
    throw new Error(`Response status is ${response.status}`);
  }

  return response.json();
}

export async function jsonBodyFetch<S, T>(path: string, method: string, args: S): Promise<T> {
  const apiPath = `${configuration.api.url}${path}`;
  const response = await fetch(apiPath, {
    headers: { "Content-type": "application/json" },
    method,
    body: JSON.stringify(args),
  });

  if (response.status === 401) {
    throw new Error(`Unauthorized`);
  }

  if (response.status >= 400) {
    if (response.status === 400) {
      const body = await response.json();
      throw body;
    }
    throw new Error(`Response status is ${response.status}`);
  }

  return response.json();
}
