import { Container } from "./Container";

export function Footer() {
  return (
    <div className="font-normal text-slate-700 mt-10 bg-neutral-100 py-6">
      <Container>
        <div className="flex justify-center text-sm">
          <a href="https://animaltree.de/datenschutz/" target="_blank" rel="noreferrer">
            Datenschutz
          </a>
          <span className="px-3">|</span>
          <a href="https://animaltree.de/agb/" target="_blank" rel="noreferrer">
            AGB
          </a>
          <span className="px-3">|</span>
          <a href="https://animaltree.de/impressum/" target="_blank" rel="noreferrer">
            Impressum
          </a>
        </div>
      </Container>
    </div>
  );
}
